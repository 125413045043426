import React, { useRef } from "react"
import { MdUnfoldMore, MdUnfoldLess } from "react-icons/md"
import { useSpring, useChain } from "react-spring"

import { colors } from "../../styles/variables.styles"
import { BodyText } from "../../styles/typography.styles"
import {
  ProductGroupTitle,
  ContentWrapper,
  ProductsSection,
  Header,
  ToggleNavButton,
} from "./Products.styles"

import PortableText from "../PortableText"
import ProductsGallery from "../Gallery/ProductsGallery"
import DownloadLinks from "./DownloadLinks"

const ProductsDisplay = ({
  shoudRenderNav,
  navIsVisible,
  navItems,
  visibleContent,
  fullscreen,
  setFullscreen,
  setActiveContentId,
  updatevisibleContent,
  onToggleNav,
  activeContentId,
}) => {
  const springRef = useRef()
  const contentAnimationProps = useSpring({
    ref: springRef,
    opacity: fullscreen ? "0" : "1",
  })

  const springRef2 = useRef()
  const galleryAnimationProps = useSpring({
    ref: springRef2,
    flex: fullscreen ? "0 0 100%" : "0 0 50%",
  })

  useChain(fullscreen ? [springRef, springRef2] : [springRef2, springRef], [
    0,
    fullscreen ? 0.2 : 0.6,
  ])

  const { _rawDescription, title, downloadLinks, gallery } = visibleContent

  return (
    <ProductsSection fullscreen={fullscreen}>
      <ContentWrapper
        fullscreen={fullscreen ? "yes" : "no"}
        style={contentAnimationProps}
      >
        <Header onClick={onToggleNav}>
          {shoudRenderNav ? (
            <ToggleNavButton>
              {navIsVisible ? (
                <MdUnfoldLess color={colors.primary.blue} />
              ) : (
                <MdUnfoldMore color={colors.primary.blue} />
              )}
            </ToggleNavButton>
          ) : null}
          <ProductGroupTitle>{title}</ProductGroupTitle>
          {shoudRenderNav &&
            navIsVisible &&
            navItems.map(group => (
              <ProductGroupTitle
                onClick={() => setActiveContentId(group._key)}
                key={group._key}
              >
                {group.title}
              </ProductGroupTitle>
            ))}
        </Header>
        {!navIsVisible && (
          <>
            <BodyText>
              <PortableText blocks={_rawDescription} />
            </BodyText>
            <DownloadLinks content={downloadLinks} />
          </>
        )}
      </ContentWrapper>
      <ProductsGallery
        title={title}
        animationProps={galleryAnimationProps}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        activeContentId={activeContentId}
        updatevisibleContent={updatevisibleContent}
        setActiveContentId={setActiveContentId}
        slides={gallery}
      />
    </ProductsSection>
  )
}

export default ProductsDisplay
