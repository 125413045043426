import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import { FiMaximize2, FiMinimize2 } from "react-icons/fi"

import { colors } from "../../styles/variables.styles"
import {
  Slides,
  Slide,
  ProductsGalleryWrapper,
  ProductsGalleryNextBtn,
  ProductsGalleryPrevtBtn,
  ProductsGalleryToggleDisplayMode,
  SlideCaption,
} from "./styles"

import ImageSlide from "./ImageSlide"
import VideoSlide from "./VideoSlide"

const ProductsGallery = ({
  slides,
  updatevisibleContent,
  setFullscreen,
  fullscreen,
  animationProps,
  title,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const safeSlides = slides.filter(({ file }) => file !== null)

  const nextSlide = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % safeSlides.length)
    if (activeIndex === slides.length - 1) {
      updatevisibleContent("next")
    }
  }

  const previousSlide = () => {
    setActiveIndex(
      prevIndex => (prevIndex - 1 + safeSlides.length) % safeSlides.length
    )
    if (activeIndex === 0) {
      updatevisibleContent("previous")
    }
  }

  return safeSlides.length > 0 ? (
    <ProductsGalleryWrapper
      style={animationProps}
      mode={fullscreen ? "fullscreen" : "default"}
    >
      <Slides>
        {safeSlides.map(({ _key, __typename, ...props }, index) => {
          return (
            <Fragment key={_key}>
              <Slide className={index === activeIndex ? "active" : "inactive"}>
                {__typename === "SanityFigure" ? (
                  <ImageSlide {...props} />
                ) : (
                  <VideoSlide active={index === activeIndex} {...props} />
                )}
              </Slide>
              {props.caption && (
                <SlideCaption
                  fullscreen={fullscreen}
                  className={index === activeIndex ? "active" : "inactive"}
                >
                  <span className="group-name">
                    {fullscreen && `${title} – `}
                  </span>
                  {props.caption}
                </SlideCaption>
              )}
            </Fragment>
          )
        })}
        <ProductsGalleryToggleDisplayMode
          onClick={() => setFullscreen(!fullscreen)}
          fullscreen={fullscreen}
        >
          {fullscreen ? (
            <FiMinimize2 color={colors.primary.blue} />
          ) : (
            <FiMaximize2 color={colors.primary.blue} />
          )}
        </ProductsGalleryToggleDisplayMode>
        <ProductsGalleryPrevtBtn
          fullscreen={fullscreen}
          className="visible"
          onClick={previousSlide}
        >
          <MdKeyboardArrowLeft color={colors.primary.blue} />
        </ProductsGalleryPrevtBtn>
        <ProductsGalleryNextBtn
          fullscreen={fullscreen}
          className="visible"
          onClick={nextSlide}
        >
          <MdKeyboardArrowRight color={colors.primary.blue} />
        </ProductsGalleryNextBtn>
      </Slides>
    </ProductsGalleryWrapper>
  ) : null
}

ProductsGallery.propTypes = {
  slides: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.string),
}
export default ProductsGallery
