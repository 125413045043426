import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useSpring, animated, config } from "react-spring"

import { useTheme } from "../../hooks/useTheme"
import PortableText from "../PortableText"

import BroadcastProduct from "./BroadcastProduct"
import BreakableTitle from "../BreakableTitle"

import {
  MainTitle,
  TagLine,
  MainDescription,
  SolutionBottom,
  SolutionBottomInner,
  OtherProducts,
  BroadcastInner,
  BroadcastProducts,
  BroadcastInnerWrapper,
} from "./Solution.styles"

import CTADisplayWithLinks from "../CTA/CTADisplayWithLinks"

const BroadcastDetails = ({ content, others, cta }) => {
  const { title, _rawBody, tagline, productsGroups } = content

  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.slow,
  })

  const { setTheme } = useTheme()

  const wrapperRef = useRef()

  const wrapperDimensions = useRef({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    setTheme("blue")
  }, [setTheme])

  useEffect(() => {
    wrapperDimensions.current = {
      width: wrapperRef.current
        ? wrapperRef.current.offsetWidth
        : window.innerWidth,
      height: wrapperRef.current
        ? wrapperRef.current.offsetHeight
        : window.innerHeight,
    }
  }, [])

  return (
    <animated.article style={props}>
      <BroadcastInnerWrapper ref={wrapperRef}>
        <BroadcastInner>
          <header>
            <MainTitle>
              <BreakableTitle wrap={true}>{title}</BreakableTitle>
            </MainTitle>
            <TagLine>
              <BreakableTitle>{tagline}</BreakableTitle>
            </TagLine>
            <MainDescription>
              <PortableText blocks={_rawBody} />
            </MainDescription>
          </header>
          {productsGroups && productsGroups.length > 0 && (
            <BroadcastProducts>
              {productsGroups.map(({ _key, ...content }) => (
                <BroadcastProduct key={_key} {...content} />
              ))}
            </BroadcastProducts>
          )}
        </BroadcastInner>
      </BroadcastInnerWrapper>
      <SolutionBottom>
        <SolutionBottomInner>
          <CTADisplayWithLinks content={cta} />
          <OtherProducts>
            <div className="content">
              {others.map(solution => (
                <Link
                  key={solution._id}
                  to={`/products/${solution.slug.current}`}
                >
                  <BreakableTitle>{solution.title}</BreakableTitle>
                </Link>
              ))}
            </div>
          </OtherProducts>
        </SolutionBottomInner>
      </SolutionBottom>
    </animated.article>
  )
}

BroadcastDetails.propTypes = {
  match: PropTypes.object,
}

export default BroadcastDetails
