import styled from "@emotion/styled"
import { headline2 } from "../../styles/typography.styles"
import { animated } from "react-spring"
import { halfWrapper, wrapper } from "../../styles/shared.styles"
import { mq } from "../../styles/helpers.styles"
import { spacing } from "../../styles/variables.styles"

export const ProductsSection = styled.section`
  position: relative;
  display: grid;
  grid-template-areas:
    "gallery"
    "content";
  grid-gap: ${`calc(${spacing.mobile} * 2)`};

  ${mq.tablet} {
    grid-gap: ${`calc(${spacing.tablet} * 2)`};
  }
  ${mq.laptop} {
  }
  ${mq.desktop} {
    display: flex;
    padding: ${({ fullscreen }) =>
      fullscreen ? `0px ${spacing.desktop}` : "0px"};
  }
  ${mq.desktop__l} {
    padding: ${({ fullscreen }) =>
      fullscreen ? `0px ${spacing.desktop__l}` : "0px"};
  }
`

export const ContentWrapper = styled(animated.div)`
  ${wrapper}
  grid-area: content;
  ${mq.laptop} {
  }
  ${mq.desktop} {
    ${halfWrapper}
    flex: 0 1 50%;
    padding: ${({ fullscreen }) =>
      fullscreen === "yes"
        ? "0px"
        : `${spacing.desktop} calc(${spacing.desktop}/2) ${spacing.desktop} ${spacing.desktop}`};
    overflow: ${({ fullscreen }) =>
      fullscreen === "yes" ? "hidden" : "visible"};
    height: ${({ fullscreen }) => (fullscreen === "yes" ? "0px" : "auto")};
    margin: 0 0 0 auto;
    display: ${({ fullscreen }) => (fullscreen === "yes" ? "none" : "block")};
  }
  ${mq.desktop__l} {
    padding: ${({ fullscreen }) =>
      fullscreen === "yes"
        ? "0px"
        : `${spacing.dekstop__l} calc(${spacing.dekstop__l} / 2) ${spacing.dekstop__l} ${spacing.dekstop__l}`};
  }
`

export const Header = styled.header`
  position: relative;
  cursor: pointer;
  margin-top: 2em;
  ${mq.desktop} {
    margin-top: 0em;
  }
`

export const ToggleNavButton = styled.div`
  position: absolute;
  left: -26px;
  top: 5px;
  font-size: 3.2em;

  ${mq.tablet} {
    left: -40px;
    top: 8px;
    font-size: 3.5em;
  }
`

export const ProductGroupTitle = styled.h2`
  ${headline2}
  margin: 0em 0em 0.5em;
  ${mq.desktop} {
    margin: 0em 0em 0.25em;
  }
`
