import React from "react"
import { graphql } from "gatsby"
import SolutionContainer from "../components/Solution"

export const getSolution = graphql`
  query getSolution($slug: String) {
    cta: sanitySolutionsSettings(_id: { eq: "settings" }) {
      ctaTitle
      ctaLinks: links {
        _key
        title
        landingPageRoute {
          slug {
            current
          }
        }
        route
        externalLink {
          href
        }
      }
    }
    solution: sanitySolution(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      tagline
      slug {
        current
      }
      seo {
        ...SEO
      }
      productsGroups {
        ... on SanityBasicProduct {
          title
          _key
          tagLine
          url
        }
        ... on SanityProductGroup {
          _key
          title
          _rawDescription
          gallery {
            ...SanityFigure
            ...VideoFields
          }
          downloadLinks {
            _key
            title
            file {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`
const Solution = ({ data, pageContext }) => {
  return (
    <SolutionContainer
      cta={data.cta}
      content={data.solution}
      others={pageContext.others}
    />
  )
}

export default Solution
