import React from "react"
import {
  DownloadLinksWrapper,
  DownloadLinksItem,
  DownloadLinksIcon,
  DownloadLinksLink,
} from "./DownloadLinks.styles"

const DownloadLinks = ({ content }) => {
  return content && content.length > 0 ? (
    <DownloadLinksWrapper>
      <ul>
        {content.map(({ title, _key, file }) => (
          <DownloadLinksItem key={_key}>
            <DownloadLinksIcon />
            <DownloadLinksLink href={`${file.asset.url}?dl=`}>
              {title} Technical Sheet.
            </DownloadLinksLink>
          </DownloadLinksItem>
        ))}
      </ul>
    </DownloadLinksWrapper>
  ) : null
}

export default DownloadLinks
