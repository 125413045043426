import React from "react"
import {
  ProductTitle,
  ProductTag,
  ProductWrapper,
} from "./BroadcastProduct.styles"

const BroadcastProduct = ({ title, tagLine, url }) => {
  return (
    <ProductWrapper>
      <header>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <ProductTitle>{title}</ProductTitle>
          <ProductTag>{tagLine}</ProductTag>
        </a>
      </header>
    </ProductWrapper>
  )
}

export default BroadcastProduct
