import styled from "@emotion/styled"
import { BsArrowDownShort } from "react-icons/bs"
import { colors } from "../../styles/variables.styles"
import { mq } from "../../styles/helpers.styles"

export const DownloadLinksWrapper = styled.section`
  margin-top: 2em;

  ${mq.desktop} {
    margin-top: 4em;
  }
`

export const DownloadLinksItem = styled.li`
  font-size: 2rem;
  margin-bottom: 0.75em;
  display: flex;
  align-items: flex-start;
  font-size: 1.4rem;
  line-height: 1.5em;

  ${mq.tablet} {
    font-size: 2rem;
  }

  ${mq.desktop} {
    font-size: 2.2rem;
  }
`

export const DownloadLinksIcon = styled(BsArrowDownShort)`
  font-size: 1.6em;
  color: ${colors.primary.blue};
  margin-right: 0.24em;
  transform: translateX(-0.2em);
`

export const DownloadLinksLink = styled.a`
  color: ${colors.primary.blue};
`
