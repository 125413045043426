import React, { useState } from "react"

import ProductsDisplay from "./ProductsDisplay"

const ProductsContainer = ({ content }) => {
  const initialState = content[0]._key
  const [activeContentId, setActiveContentId] = useState(initialState)
  const [fullscreen, setFullscreen] = useState(false)
  const [navIsVisible, setNavVisibility] = useState(false)

  function updatevisibleContent(direction) {
    const currentVisibleIndex = content.findIndex(
      ({ _key }) => _key === activeContentId
    )
    const last = content.length - 1
    let nextVisibleIndex
    if (direction === "next") {
      nextVisibleIndex =
        currentVisibleIndex === last ? 0 : currentVisibleIndex + 1
    } else if (direction === "previous") {
      nextVisibleIndex =
        currentVisibleIndex === 0 ? last : currentVisibleIndex - 1
    }
    setActiveContentId(content[nextVisibleIndex]._key)
  }

  function onToggleNav() {
    if (content.length <= 1) return
    setNavVisibility(!navIsVisible)
  }

  const visibleContent = content.find(({ _key }) => _key === activeContentId)
  const shoudRenderNav = content.length > 1
  const navItems =
    content && content.filter(({ _key }) => visibleContent._key !== _key)

  return (
    <ProductsDisplay
      visibleContent={visibleContent}
      shoudRenderNav={shoudRenderNav}
      navIsVisible={navIsVisible}
      navItems={navItems}
      setActiveContentId={setActiveContentId}
      activeContentId={activeContentId}
      fullscreen={fullscreen}
      setFullscreen={setFullscreen}
      updatevisibleContent={updatevisibleContent}
      onToggleNav={onToggleNav}
    />
  )
}

export default ProductsContainer
