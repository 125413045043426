import { fonts } from "../../styles/variables.styles"
import { headline2 } from "../../styles/typography.styles"
import styled from "@emotion/styled"

export const ProductWrapper = styled.div`
  position: relative;
  max-width: 900px;
`

export const ProductTitle = styled.h2`
  ${headline2};
  color: #fff;
`

export const ProductTag = styled.h3`
  font-family: ${fonts.bold};
  color: #000;
  font-size: 2.2rem;
  margin: 0;
`
