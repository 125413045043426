import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { animated } from "react-spring"
import { Link } from "gatsby"

import {
  colors,
  fonts,
  fixedValues,
  spacing,
} from "../../styles/variables.styles"
import { wrapper } from "../../styles/shared.styles"
import { mq } from "../../styles/helpers.styles"
import { headline2, bigText } from "../../styles/typography.styles"

const shared_background_styles = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`

export const Background = styled.div`
  ${shared_background_styles}
`

export const Solution = styled(animated.article)``

const inner_wrapper = css`
  position: relative;
  overflow: hidden;
  background: ${colors.primary.blue};
`

export const SolutionInnerWrapper = styled.div`
  ${inner_wrapper}
`
export const BroadcastInnerWrapper = styled.div`
  ${inner_wrapper}
`

const solution_top_wrapper_styles = css`
  ${wrapper}
  position: relative;
  z-index: 1;
  padding-top: ${`calc(${fixedValues.headerHeightMobile} + 5vh)`};
  padding-bottom: 5vh;
  ${mq.tablet} {
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
    padding-bottom: 5vh;
  }
  ${mq.desktop} {
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
    padding-bottom: calc(120px + 2vh);
  }
  ${mq.desktop__l} {
    padding-top: ${`calc(${fixedValues.largeHeaderHeight} + 5vh)`};
  }
`

export const Header = styled.header`
  ${solution_top_wrapper_styles}
`

export const BroadcastInner = styled.div`
  ${solution_top_wrapper_styles}
`

export const BroadcastProducts = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-top: 4em;
  grid-gap: 4em;
  ${mq.laptop} {
    padding-top: 8em;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const MainTitle = styled.h1`
  font-size: 2rem;
  line-height: 1em;
  margin-bottom: 1em;
  color: #fff;
  ${mq.tablet} {
    font-size: 2.4rem;
  }
  ${mq.laptop} {
    font-size: 2.8rem;
  }
  ${mq.desktop} {
    font-size: 3.2em;
  }
  ${mq.desktop__l} {
    font-size: 4.8em;
  }
`

export const TagLine = styled.h2`
  font-family: ${fonts.title};
  font-size: 5.8em;
  line-height: 1em;
  letter-spacing: 0.025em;
  color: #000;
  ${mq.tablet} {
    font-size: 7.2em;
  }
  ${mq.laptop} {
    font-size: 9.2em;
  }
  ${mq.desktop} {
    font-size: 11.2em;
  }
  ${mq.desktop__l} {
    font-size: 12.2em;
  }
`

export const MainDescription = styled.div`
  ${bigText};
  margin-top: 1em;
  color: #fff;
`

export const NavTitle = styled(animated.h2)`
  cursor: pointer;
  color: #fff;
  font-size: 3.2em;
  line-height: 1em;
  font-family: ${fonts.bold};
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
`

export const ExpandButton = styled(Link)`
  position: absolute;
  bottom: 32px;
  left: 50%;
  font-family: ${fonts.bold};
  transform: translateX(-50%);
  color: #fff;
  z-index: 2;
  width: 42px;
  height: 42px;
  svg {
    width: 100%;
    height: auto;
    box-shadow: 0px 1px 4px 1px #0f74c5;
    border-radius: 100%;
    #Oval {
      fill: ${colors.primary.blue};
    }
    #Icon {
      fill: #fff;
    }
  }
`

export const Description = styled.div`
  color: #fff;
  font-size: 2.2em;
  line-height: 1.5em;
  margin-top: 48px;
`

export const SolutionBottom = styled.aside`
  ${wrapper}
  margin: 4em auto;
  ${mq.tablet} {
    margin: 8em auto;
  }
  a {
    display: block;
    margin-bottom: 0.5em;
  }
`

export const SolutionBottomInner = styled.div`
  display: grid;
  gap: calc(${spacing.mobile} * 2);
  ${mq.tablet} {
    gap: ${spacing.tablet};
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.laptop} {
  }
  ${mq.desktop} {
    gap: ${spacing.desktop};
  }

  ${mq.desktop__l} {
    gap: ${spacing.desktop__l};
  }
`

export const OtherProducts = styled.div`
  ${headline2}
  a {
    font-size: 0.8em;
    margin-bottom: 0.5em;
    &:hover {
      text-decoration: none;
    }
    ${mq.laptop} {
      margin-bottom: 0.25em;
    }
  }
`
