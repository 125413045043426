import React from "react"
import { CTAWrapper, CTALinks, CTATitle } from "./CTA.styles"
import CTALink from "./CTALink"

const CTADisplayWithLinks = ({ content }) => {
  const { ctaTitle, ctaLinks } = content
  return (
    <CTAWrapper>
      <CTATitle>{ctaTitle}</CTATitle>
      {ctaLinks && (
        <CTALinks className="links">
          {ctaLinks.map(link => {
            if(link.title != "Book online!"){
              return <CTALink key={link._key} {...link} />
            }else{
              return null
            }
          })}
        </CTALinks>
      )}
    </CTAWrapper>
  )
}

export default CTADisplayWithLinks
