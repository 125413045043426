import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { useTheme } from "../../hooks/useTheme"

import SEO from "../seo"

import SolutionDisplay from "./SolutionDisplay"
import BroadcastDetails from "./BroadcastDetails"

const SolutionContainer = ({ content, others, cta }) => {
  const { slug, seo } = content
  const { theme, setTheme } = useTheme()

  useEffect(() => {
    setTheme("blue")
  }, [setTheme])

  return (
    <>
      <SEO {...seo} title={seo.title || content.title} />
      {slug.current === "broadcast" ? (
        <BroadcastDetails
          cta={cta}
          theme={theme}
          content={content}
          others={others}
        />
      ) : (
        <SolutionDisplay
          cta={cta}
          theme={theme}
          content={content}
          others={others}
        />
      )}
    </>
  )
}

SolutionContainer.propTypes = {
  match: PropTypes.object,
}

export default SolutionContainer
