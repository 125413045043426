import React from "react"
import PropTypes from "prop-types"
import { useSpring, config } from "react-spring"
import { Link } from "gatsby"

import PortableText from "../PortableText"
import BreakableTitle from "../BreakableTitle"

import ProductsContainer from "./ProductsContainer"
import CTADisplayWithLinks from "../CTA/CTADisplayWithLinks"

import {
  MainTitle,
  TagLine,
  MainDescription,
  Header,
  Solution,
  SolutionInnerWrapper,
  OtherProducts,
  SolutionBottom,
  SolutionBottomInner,
} from "./Solution.styles"

const SolutionDisplay = ({ content, others, cta }) => {
  const { title, _rawBody, tagline, productsGroups } = content

  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.slow,
  })

  return (
    <Solution style={props}>
      <SolutionInnerWrapper>
        <Header>
          <MainTitle>
            <BreakableTitle wrap={true}>{title}</BreakableTitle>
          </MainTitle>
          <TagLine>
            <BreakableTitle>{tagline}</BreakableTitle>
          </TagLine>
          <MainDescription>
            <PortableText blocks={_rawBody} />
          </MainDescription>
        </Header>
      </SolutionInnerWrapper>
      {productsGroups && <ProductsContainer content={productsGroups} />}
      <SolutionBottom>
        <SolutionBottomInner>
          <CTADisplayWithLinks type="links" content={cta} />
          <OtherProducts>
            <div className="content">
              {others.map(solution => (
                <Link
                  key={solution._id}
                  to={`/products/${solution.slug.current}`}
                >
                  <BreakableTitle>{solution.title}</BreakableTitle>
                </Link>
              ))}
            </div>
          </OtherProducts>
        </SolutionBottomInner>
      </SolutionBottom>
    </Solution>
  )
}

SolutionDisplay.propTypes = {
  match: PropTypes.object,
}

export default SolutionDisplay
